import * as React from "react";
import * as styles from "./header.module.scss";
import {Link} from "gatsby";

const Header = ({isBg})=>{


  return (
    <header className={`${styles.header} ${isBg ? styles.bg : ''}`}>
      <div className={styles.headerWrap}>
        <a className={styles.logo} href={'/'}>
          AI solutions
          {/*<svg viewBox="0 0 108 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
          {/*  <path d="M17.2115 8.57294C17.2115 9.22671 17.1692 9.81722 17.0847 10.3445H3.74025C3.84591 11.7364 4.36363 12.8541 5.29341 13.6977C6.22319 14.5413 7.36428 14.9631 8.71669 14.9631C10.6608 14.9631 12.0343 14.1511 12.8373 12.5272H16.736C16.2078 14.1301 15.2463 15.4482 13.8516 16.4815C12.4781 17.4938 10.7664 18 8.71669 18C7.04731 18 5.54698 17.6309 4.21571 16.8928C2.90556 16.1336 1.87013 15.0791 1.1094 13.7294C0.369799 12.3585 0 10.7768 0 8.98418C0 7.19156 0.359233 5.62039 1.0777 4.27065C1.8173 2.89982 2.84217 1.84534 4.15231 1.10721C5.48359 0.369069 7.00505 0 8.71669 0C10.3649 0 11.8336 0.358524 13.1226 1.07557C14.4116 1.79262 15.4153 2.80492 16.1338 4.11248C16.8523 5.39895 17.2115 6.88576 17.2115 8.57294ZM13.4395 7.43409C13.4184 6.10545 12.943 5.04042 12.0132 4.23902C11.0834 3.43761 9.93174 3.03691 8.5582 3.03691C7.31145 3.03691 6.24432 3.43761 5.3568 4.23902C4.46928 5.01933 3.941 6.08436 3.77195 7.43409H13.4395Z" fill="white"/>*/}
          {/*  <path d="M27.519 14.4886L32.4637 0.284711H36.2991L29.6427 17.7153H25.3319L18.7072 0.284711H22.5742L27.519 14.4886Z" fill="white"/>*/}
          {/*  <path d="M42.6439 2.81547C43.1722 1.9297 43.8695 1.24429 44.7359 0.759227C45.6234 0.253076 46.6694 0 47.8739 0V3.73286H46.9547C45.5389 3.73286 44.4612 4.09139 43.7216 4.80844C43.0031 5.52548 42.6439 6.76977 42.6439 8.5413V17.7153H39.0305V0.284711H42.6439V2.81547Z" fill="white"/>*/}
          {/*  <path d="M58.771 18C57.1228 18 55.633 17.6309 54.3017 16.8928C52.9705 16.1336 51.9245 15.0791 51.1637 13.7294C50.403 12.3585 50.0226 10.7768 50.0226 8.98418C50.0226 7.21265 50.4136 5.64148 51.1954 4.27065C51.9773 2.89982 53.0444 1.84534 54.3968 1.10721C55.7492 0.369069 57.2601 0 58.9295 0C60.5989 0 62.1098 0.369069 63.4622 1.10721C64.8146 1.84534 65.8817 2.89982 66.6636 4.27065C67.4454 5.64148 67.8364 7.21265 67.8364 8.98418C67.8364 10.7557 67.4349 12.3269 66.6319 13.6977C65.8289 15.0685 64.7301 16.1336 63.3354 16.8928C61.9619 17.6309 60.4404 18 58.771 18ZM58.771 14.8682C59.7008 14.8682 60.5672 14.6467 61.3702 14.2039C62.1943 13.761 62.8599 13.0967 63.3671 12.2109C63.8742 11.3251 64.1278 10.2496 64.1278 8.98418C64.1278 7.7188 63.8848 6.65378 63.3988 5.7891C62.9128 4.90334 62.2683 4.23902 61.4653 3.79613C60.6623 3.35325 59.7959 3.13181 58.8661 3.13181C57.9363 3.13181 57.07 3.35325 56.267 3.79613C55.4851 4.23902 54.8617 4.90334 54.3968 5.7891C53.9319 6.65378 53.6995 7.7188 53.6995 8.98418C53.6995 10.8612 54.175 12.3163 55.1259 13.3497C56.0979 14.362 57.313 14.8682 58.771 14.8682Z" fill="white"/>*/}
          {/*  <path d="M80.2203 0C81.5938 0 82.8194 0.28471 83.8971 0.854128C84.996 1.42355 85.8518 2.26713 86.4646 3.38489C87.0774 4.50264 87.3838 5.85237 87.3838 7.43409V17.7153H83.802V7.97188C83.802 6.41125 83.4111 5.21968 82.6292 4.39719C81.8474 3.5536 80.7802 3.13181 79.4278 3.13181C78.0754 3.13181 76.9977 3.5536 76.1947 4.39719C75.4129 5.21968 75.0219 6.41125 75.0219 7.97188V17.7153H71.4085V0.284711H75.0219V2.27768C75.6136 1.56063 76.3638 1.00176 77.2724 0.601054C78.2022 0.200351 79.1848 0 80.2203 0Z" fill="white"/>*/}
          {/*  <path d="M108 8.57294C108 9.22671 107.958 9.81722 107.873 10.3445H94.5287C94.6344 11.7364 95.1521 12.8541 96.0819 13.6977C97.0117 14.5413 98.1528 14.9631 99.5052 14.9631C101.449 14.9631 102.823 14.1511 103.626 12.5272H107.525C106.996 14.1301 106.035 15.4482 104.64 16.4815C103.267 17.4938 101.555 18 99.5052 18C97.8358 18 96.3355 17.6309 95.0042 16.8928C93.6941 16.1336 92.6586 15.0791 91.8979 13.7294C91.1583 12.3585 90.7885 10.7768 90.7885 8.98418C90.7885 7.19156 91.1477 5.62039 91.8662 4.27065C92.6058 2.89982 93.6307 1.84534 94.9408 1.10721C96.2721 0.369069 97.7935 0 99.5052 0C101.153 0 102.622 0.358524 103.911 1.07557C105.2 1.79262 106.204 2.80492 106.922 4.11248C107.641 5.39895 108 6.88576 108 8.57294ZM104.228 7.43409C104.207 6.10545 103.731 5.04042 102.802 4.23902C101.872 3.43761 100.72 3.03691 99.3467 3.03691C98.1 3.03691 97.0328 3.43761 96.1453 4.23902C95.2578 5.01933 94.7295 6.08436 94.5605 7.43409H104.228Z" fill="white"/>*/}
          {/*</svg>*/}
        </a>
        <div className={styles.menu}>
          <div className={styles.mobileMenu}>
            <div className={styles.menuItem}><Link className={styles.link} to={'/#company'}>Company</Link></div>
            <div className={styles.menuItem}><a className={styles.link} href={'/#services'}>Services</a></div>
            <div className={styles.menuItem}><a className={styles.link} href={'/#expertise'}>Expertise</a></div>
          </div>
          <div className={styles.menuItem}><Link className={`${styles.link} ${styles.button}`} to={'/#contacts'} >Hire developers</Link></div>
        </div>
      </div>
    </header>
  )

}

export default Header
