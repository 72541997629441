import * as React from "react";
import * as styles from "./footer.module.scss"
import {Link} from "gatsby";

const Footer = ()=>{
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrap}>
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.left}`}>
            <a className={styles.item} href={'https://www.facebook.com/aisolutionskz/'}>Facebook</a>
            <a className={styles.item} href={'https://twitter.com/AISolutions10'}>Twitter</a>
          </div>
          <div className={`${styles.col} ${styles.right}`}>
            <Link className={styles.item} to={'/terms'}>Terms of use</Link>
            <Link className={styles.item} to={'/privacy'}>Privacy policy</Link>
          </div>
        </div>
      </div>
    </footer>
  )

}

export default Footer