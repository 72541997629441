/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header";
import "./layout.css"
import "../scss/root-vars.scss"
import "./../scss/typografy.scss"
import Footer from "./footer/footer";

const Layout = ({ children, isBg }) => {

  return (
    <>
      <Header isBg={isBg}/>
      <div>
        <main>{children}</main>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
